import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { withAppContext } from '../../hoc/withAppContext';
import { getCurrentUser } from '../../services/CurrentUser';
import { isFeatureEnabled } from '../../services/Features';
import { getDisplayedName, getLink } from '../../services/ProfileInfo';
import { isAnonymous, isTaskUser, isDeleted } from '../../services/User';
import Avatar from '../Avatar';
import Link from '../Link';
import styles from './style.m.less';
export const toUserKey = (user, i) => {
    if (isAnonymous(user)) {
        return `${user.id}-${i}`;
    }
    return user.id;
};
const Wrapper = ({ profileUrl, className, openInNewTab, children, userId, eventTrackingCategory }) => profileUrl ? (_jsx(Link, { href: profileUrl, className: className, openInNewTab: openInNewTab, eventTracking: eventTrackingCategory
        ? { category: eventTrackingCategory, action: 'navigate to profile', label: userId }
        : undefined, children: children })) : (_jsx("span", { className: className, children: children }));
const MiniProfile = ({ user, appContext, isInline = false, className, noLink = false, avatarSize = 'XS', noAvatar = false, ellipsize = false, useFirstName = false, openInNewTab = false, eventTrackingCategory, }) => {
    var _a;
    noLink =
        noLink ||
            isAnonymous(user) ||
            isDeleted(user) ||
            isTaskUser(user) ||
            // MiniProfile can be rendered outside SPA context (Praise Wall), currentUser might not be available
            (((_a = getCurrentUser()) === null || _a === void 0 ? void 0 : _a.isGuest) && !isFeatureEnabled('FEATURE_guestUserProfileAccess'));
    ellipsize = ellipsize && !isInline;
    const userName = getDisplayedName(appContext.user, user, useFirstName);
    const profileUrl = noLink ? null : getLink(user);
    const userNameClass = classNames({
        [styles.ellipsize]: ellipsize,
    });
    const wrapperClass = classNames(styles.link, className, {
        [styles.inline]: isInline,
        [styles.deactivated]: !user.isActive,
        [styles.ellipsizeWrapper]: ellipsize,
    });
    return (_jsxs(Wrapper, { profileUrl: profileUrl, className: wrapperClass, openInNewTab: openInNewTab, userId: user.id, eventTrackingCategory: eventTrackingCategory, children: [!noAvatar &&
                !!user.logo &&
                (isInline ? (_jsx("span", { className: styles.inlineAvatarWrapper, children: _jsx(Avatar, { user: user, size: avatarSize, ariaHidden: true }) })) : (_jsx(Avatar, { user: user, size: avatarSize, ariaHidden: true }))), _jsx("span", { className: userNameClass, children: userName })] }));
};
MiniProfile.propTypes = {
    isInline: PropTypes.bool,
    noLink: PropTypes.bool,
    noAvatar: PropTypes.bool,
    ellipsize: ((...args) => {
        const [props, propName, componentName] = args;
        if (props.isInline && props[propName]) {
            return new Error(`${componentName}: using isInline and ${propName} together is not supported`);
        }
        else {
            return PropTypes.bool(...args);
        }
    }),
    openInNewTab: PropTypes.bool,
    useFirstName: PropTypes.bool,
    className: PropTypes.string,
    eventTrackingCategory: PropTypes.any,
};
export default withAppContext(MiniProfile);
